<template>
    <section>
        <div class="w-full my-5">
            <div class="flex">
                <back-button />
                <router-link
                :to="{ name:'pharmasan.administrativa.compras-administrativas.ajustes.presentacion.formulario'}"
                class="btn btn-primary ml-2 flex items-center"
                >
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
                </svg>
                <span class="ml-2"> Agregar </span>
                </router-link>
            </div>
        </div>
    <div class="my-5">
      <h1 class="font-bold text-xl">Presentación</h1>
    </div>
    <div class="w-full">
      <DataTable :value="getTaxes">
        <Column header="ID" field="id" headerStyle="width:70px;" />
        <Column header="Presentación" field="name" headerStyle="width:170px;" sortable/>
        <Column header="Factor" field="factor" sortable />
        <Column field="state" header="Habilitado" sortable>
            <template #body="{data}">
                <span>{{data.state?'Si':'No'}}</span>
            </template>
        </Column>
        <Column header="Unidad Medida" field="unidad.name" sortable/>
        <Column header="Acciones" headerStyle="width:120px;" >
          <template #body="{data}">
            <router-link
            :to="{name:'pharmasan.administrativa.compras-administrativas.ajustes.presentacion.formulario', params:{ id: data.id}}"
            class="btn btn-primary p-1"
            >
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
               <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
              </svg>
            </router-link>
          </template>
        </Column>
      </DataTable >
    </div>
    </section>
</template>
<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { computed, onMounted, ref } from 'vue'
import presentacionesListar from '../../../../../services/presentacionesListar'
export default {
  components: {
    DataTable,
    Column
  },
  setup () {
    const taxes = ref([])
    const getTaxes = computed(() => {
      return taxes.value
    })
    const fetchPresentaciones = () => {
      return presentacionesListar().then(({ data }) => {
        taxes.value = data
      }).catch(err => {
        console.error(err.message)
      })
    }
    onMounted(() => {
      fetchPresentaciones()
    })
    return {
      getTaxes
    }
  }
}
</script>
